.undo {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(30, 30, 90);
  bottom: 0;
}

.undoButton {
  padding: 0.5em;
  border: 0 none;
  font-size: inherit;
  transition: all 0.3s ease-in-out;
}
