.listContainer {
  padding: var(--toolbarHeight) 0;
}

.items {
  width: 100%;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  list-style: none;
  margin: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.button {
  background: transparent;
  width: 100%;
  font-size: 22px;
  border: 0 none;
  padding: 1em;
}

.buttonInner {
  max-width: 20em;
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
}

@keyframes popout {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.tick {
  font-size: 18px;
  border: 0 none;
  align-self: flex-end;
  margin-left: auto;
  font-size: 35px;
  height: 45px;
  width: 45px;
  padding: 0;
  color: white;
  background: var(--green);
  border-radius: 50%;
  transform: scale(0);
  opacity: 0;
  transition: 0.1s all ease-in;
  position: relative;
  z-index: 99;
}

.tick:hover {
  opacity: 0.6;
}

.tick:active {
  opacity: 0.2;
}

.showTick {
  opacity: 1;
  transform: scale(1);
  transition: 0.3s all cubic-bezier(0.51, 1.31, 0.37, 1.23);
}
