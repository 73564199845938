.toolBar {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
  background-color: #282c34;
  box-sizing: border-box;
  padding: 1em;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--toolbarHeight);
  z-index: 9;
}

@media only screen and (max-width: 700px) {
  .toolBar {
    column-gap: 3vw;
    padding: 3vw;
    height: auto;
  }
}

.title {
  color: white;
  margin: 0;
}

@media only screen and (max-width: 700px) {
  .title {
    font-size: 5vw;
  }
}

.addItem {
  padding: 0.5em 0;
  font-size: 20px;
  text-indent: 1em;
  box-sizing: border-box;
  min-width: 100%;
}

.itemAdded,
.itemRemoved {
  position: fixed;
  width: 25vw;
  top: var(--toolbarHeight);
  color: white;
  padding: 0.5em 0em 2em 1.5em;
}

.itemAdded {
  left: 0;
  background: var(--green);
}

.itemRemoved {
  right: 0;
  background: var(--red);
}

.refreshItems {
  padding: 0;
  margin: 0;
}
