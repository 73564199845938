@import url("https://fonts.googleapis.com/css?family=Comfortaa");

:root {
  --toolbarHeight: 90px;
  --green: rgb(100, 150, 0);
  --red: rgb(150, 50, 0);
}

html {
  box-sizing: border-box;
  background: #eee;
}

body {
  font-family: "Comfortaa", cursive;
  letter-spacing: -0.045em;
}

* {
  font-family: inherit;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:link:not(:hover) {
  text-decoration: none;
}
