.wrapper {
  pointer-events: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  padding: 2em;
  background-color: rgba(233, 134, 20, 0.9);
  color: white;
  max-width: 50vw;
  border-radius: 1em;
  box-shadow: 0 0.35em 0.2em -0.3em rgba(0, 0, 0, 0.4);
}
