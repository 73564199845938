@import url(https://fonts.googleapis.com/css?family=Comfortaa);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Undo_undo__1k6hQ {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: rgba(30, 30, 90);
  bottom: 0;
}

.Undo_undoButton__T93rs {
  padding: 0.5em;
  border: 0 none;
  font-size: inherit;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.Items_listContainer__1N9T4 {
  padding: var(--toolbarHeight) 0;
}

.Items_items__2ZKVS {
  width: 100%;
  padding: 0;
  margin: 0;
}

.Items_item__2wDiq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: left;
  width: 100%;
  list-style: none;
  margin: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.Items_button__9avTA {
  background: transparent;
  width: 100%;
  font-size: 22px;
  border: 0 none;
  padding: 1em;
}

.Items_buttonInner__1uW2k {
  max-width: 20em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
  width: 100%;
}

@-webkit-keyframes Items_popout__1WItf {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes Items_popout__1WItf {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Items_tick__1A6z0 {
  font-size: 18px;
  border: 0 none;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-left: auto;
  font-size: 35px;
  height: 45px;
  width: 45px;
  padding: 0;
  color: white;
  background: var(--green);
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transition: 0.1s all ease-in;
  transition: 0.1s all ease-in;
  position: relative;
  z-index: 99;
}

.Items_tick__1A6z0:hover {
  opacity: 0.6;
}

.Items_tick__1A6z0:active {
  opacity: 0.2;
}

.Items_showTick__aSRZv {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: 0.3s all cubic-bezier(0.51, 1.31, 0.37, 1.23);
  transition: 0.3s all cubic-bezier(0.51, 1.31, 0.37, 1.23);
}

.InfoModal_wrapper__3Ijxt {
  pointer-events: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.InfoModal_message__Fg11E {
  padding: 2em;
  background-color: rgba(233, 134, 20, 0.9);
  color: white;
  max-width: 50vw;
  border-radius: 1em;
  box-shadow: 0 0.35em 0.2em -0.3em rgba(0, 0, 0, 0.4);
}

:root {
  --toolbarHeight: 90px;
  --green: rgb(100, 150, 0);
  --red: rgb(150, 50, 0);
}

html {
  box-sizing: border-box;
  background: #eee;
}

body {
  font-family: "Comfortaa", cursive;
  letter-spacing: -0.045em;
}

* {
  font-family: inherit;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:link:not(:hover) {
  text-decoration: none;
}

.App_toolBar__3f73Z {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
          column-gap: 1em;
  background-color: #282c34;
  box-sizing: border-box;
  padding: 1em;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--toolbarHeight);
  z-index: 9;
}

@media only screen and (max-width: 700px) {
  .App_toolBar__3f73Z {
    grid-column-gap: 3vw;
    -webkit-column-gap: 3vw;
            column-gap: 3vw;
    padding: 3vw;
    height: auto;
  }
}

.App_title__1O3Wz {
  color: white;
  margin: 0;
}

@media only screen and (max-width: 700px) {
  .App_title__1O3Wz {
    font-size: 5vw;
  }
}

.App_addItem__1Ds88 {
  padding: 0.5em 0;
  font-size: 20px;
  text-indent: 1em;
  box-sizing: border-box;
  min-width: 100%;
}

.App_itemAdded__YGycC,
.App_itemRemoved__1DtJu {
  position: fixed;
  width: 25vw;
  top: var(--toolbarHeight);
  color: white;
  padding: 0.5em 0em 2em 1.5em;
}

.App_itemAdded__YGycC {
  left: 0;
  background: var(--green);
}

.App_itemRemoved__1DtJu {
  right: 0;
  background: var(--red);
}

.App_refreshItems__WBwEt {
  padding: 0;
  margin: 0;
}

